<template>
  <div>
    <v-data-table
      v-if="headers && data"
      :headers="headers"
      :items="data"
      item-key="ID"
      elevation="0"
      light
      :locale="locale"
      hide-default-footer
    >
      <template v-slot:top>
        <v-toolbar flat v-if="editedItem">
          <span style="padding: 0 20px;">
            Entrada:
          </span>
          <signature
            style="margin: 0 20px;"
            locale="es"
            :isNew="editedItem.signatureIni ? false : true"
            :onSave="handleCheckin"
            :disabled="!editedItem.signatureIni ? false : true"
          />
          <span style="padding: 0 20px;">
            Salida:
          </span>
          <signature
            style="margin-left: 20px;"
            locale="es"
            :isNew="editedItem.signatureEnd ? false : true"
            :onSave="handleCheckout"
            :disabled="editedItem.signatureIni && !editedItem.signatureEnd ? false : true"
          />
          <v-spacer />
            <v-btn
              @click="editedItem = null"
            >
              {{ $t('common.cancel', locale) }}
            </v-btn>
        </v-toolbar>
        <v-toolbar flat v-else>
          <v-spacer />
            <v-btn
              @click="handleAdd"
              color="primary"
              dark
            >
              {{ $t('common.add', locale) }}
            </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.timeIni="{ item }">
        {{ getTimeFormated(item.timeIni) }}
      </template>
      <template v-slot:item.timeEnd="{ item }">
        {{ getTimeFormated(item.timeEnd) }}
      </template>
      <template v-slot:item.signatureIni="{ item }">
        <img 
          v-if="item.signatureIni"
          :src="item.signatureIni"
          style="width: 100px;border: 1px solid #cccccc;"
        />
      </template>
      <template v-slot:item.signatureEnd="{ item }">
        <img 
          v-if="item.signatureEnd"
          :src="item.signatureEnd"
          style="width: 100px;border: 1px solid #cccccc;"
        />
      </template>
      <template v-slot:item.action="{ item }">
        <delete-item
          :id="item.id"
          :onDeleteItem="handleDeleteCheckin"
          :locale="locale"
          :onlyIcon="true"
        />
        <v-btn
          @click="handleEdit(item)"
          color="primary"
          style="margin-left: 10px;"
        >
          editar
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import Signature from '@/components/signature/Index'
import DeleteItem from '@/components/DeleteItem'
export default {
  components: {
    Signature,
    DeleteItem,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    editedItem: null,
    headers: null,
    data: null,
    /*
    data: [
      {
        id: '1.1',
        timeIni: 112,
        timeEnd: null,
        signatureEnd: null,
        arriveOnly: true,
        goAlone: false,
      },
      {
        id: '1.2',
        timeIni: 1120,
        signatureIni: null,
        timeEnd: 1140,
        signatureEnd: null,
        arriveOnly: true,
        goAlone: false,
      },
    ],
    */
    //showSignatureForm: false,
    signatureData: null,
  }),
  computed: {
    attendanceID () {
      return this.item.ID
    },
  },
  watch: {
    'item.ID' () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
    this.prepareHeaders()
  },
  methods: {
    handleGetData () {
      api.getAllWithoutLimit ('booking', `v1/private/attendance-reservations/${this.attendanceID}/checkin`)
        .then(response => {
          if (!response) return
          this.data = response.map(item => {
            return {
              id: item.ID,
              attendanceID: item.AttendanceID,
              timeIni: item.TimeIni,
              timeEnd: item.TimeEnd,
              signatureIni: item.SignatureIni,
              signatureEnd: item.SignatureEnd,
            }
          })

          if (this.data.length === 0) this.handleAdd()
        })
    },
    handleDeleteCheckin (v) {
      api.deleteItem ('booking', `v1/private/attendance-reservations/`, v.concat('/checkin'))
        .then(() => {
          this.handleGetData()
        })
    },
    prepareHeaders () {
      this.headers = [
        { text: 'Hora entrada', value: 'timeIni', sortable: false },
        { text: 'Firma entrada', value: 'signatureIni', sortable: false },
        { text: 'Hora salida', value: 'timeEnd', sortable: false },
        { text: 'Firma salida', value: 'signatureEnd', sortable: false },
        { value: 'action', sortable: false },
      ]
    },
    getTimeFormated (v) {
      return v ? utils.convertTime(v) : ''
    },
    getTimeInMinutes () {
      const now = new Date()
      return now.getHours() * 60 + now.getMinutes()
    },
    handleCheckin (v) {
      this.editedItem.timeIni = this.getTimeInMinutes()
      this.editedItem.signatureIni = v
      this.handleSave()
    },
    handleCheckout (v) {
      this.editedItem.timeEnd = this.getTimeInMinutes()
      this.editedItem.signatureEnd = v
      this.handleSave()
    },
    handleSave () {
      api.put('booking', `v1/private/attendance-reservations/${this.attendanceID}/checkin`, this.editedItem)
        .then(() => {
          this.editedItem = null
          this.handleGetData()
        } )
    },
    handleAdd () {
      this.handleEdit({
        id: utils.getNewID(),
        attendanceID: this.attendanceID,
        timeIni: null,
        signatureIni: null,
        timeEnd: null,
        signatureEnd: null,
      })
    },
    handleEdit (v) {
      this.editedItem = JSON.parse(JSON.stringify(v))
      console.log(this.editedItem)
    },
  },
}
</script>

