<template>
  <v-data-table
    v-if="headers"
    :headers="headers"
    :items="data"
    :items-per-page="itemsPerPage"
    :single-expand="singleExpand"
    :expanded.sync="expanded"
    item-key="ID"
    show-expand
    class="elevation-0"
    dark
    hide-default-footer
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title>Registro de entrada</v-toolbar-title>
        <v-spacer />
          <v-select
            v-if="activities"
            :items="activities"
            label="Actividad"
            v-model="selectedActivity"
            itemValue="id"
            itemText="tName"
            outlined
            hide-details
            dense
          />
        <v-spacer />
          <date-picker
            :onChange="handleChangeDate"
          />
        <v-btn
          v-if="1==2"
          color="primary"
          dark
          @click="handleExportPDF"
          style="margin-left: 10px"
        >
          <v-icon small>mdi-file-pdf-box</v-icon>
        </v-btn>
        <v-spacer />
        <search v-if="onSearch" :onSearch="onSearch" />
        <v-btn
          color="primary"
          @click="handleGetData"
        >
          refrescar
        </v-btn>
      </v-toolbar>
    </template>
    <template v-slot:item.ident="{ item }">
      <a @click="handleCheckin(item)">{{item.ident.name}}</a>
    </template>
    <template v-slot:item.notes="{ item }">
      <note-form
        :item="item"
        :locale="locale"
        :onRefresh="handleGetData"
      />
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn
        color="primary"
        @click="handleCheckin(item)"
        style="margin-left: 10px;"
      >
        registrar
      </v-btn>
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <expanded-item 
          :item="item"
          :locale="locale"
        />
      </td>
    </template>
    <template v-slot:footer>
      <pagination
        :startIndex="startIndex"
        :itemsPerPage="itemsPerPage"
        :count="count"
        :onGetData="handleGetData"
        :locale="locale"
        :dark="true"
        :onChangeItemsPerPage="handleChangeItemsPerPage"
      />
    </template>
  </v-data-table>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
import Pagination from '@/components/customTable/Pagination'
import Search from '@/components/Search'
import ExpandedItem from './ExpandedItem'
import DatePicker from './DatePicker'
import NoteForm from './NoteForm'
export default {
  components: {
    ExpandedItem,
    Pagination,
    Search,
    DatePicker,
    NoteForm
  },
  props: {
    locale: {
      type: String,
      required: true,
    },
    workspaceID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    activities: null,
    selectedActivity: null,

    img: null,
    expanded: [],
    singleExpand: true,
    selectedDate: new Date().toISOString().split('T').shift(),
    search: '',
    startIndex: 0,
    itemsPerPage: 5,
    count: 0,
    headers: null,
    data: [],
    selectedStatus: ['RESERVED'],
    /*data: [
      {
        id: '1',
        paxName: 'Frozen Yogurt',
        age: 4,
        room: 'K-309',
        locale: 'EN',
        guardianWhere: 'GYM',
        phone: '00 49 658594620',
        arriveOnly: true,
        goAlone: false,
        note: '',
      },
      {
        id: '2',
        paxName: 'Frozen Yogurt',
        age: 4,
        room: 'K-309',
        locale: 'EN',
        guardianWhere: 'GYM',
        phone: '00 49 658594620',
        arriveOnly: false,
        goAlone: true,
        note: '',
      },
    ],
    showSignatureForm: false,
    */
  }),
  watch: {
    selectedActivity () {
      if (!this.selectedActivity) return
      this.prepareHeaders()
    },

  },
  mounted () {
    const aux = localStorage.getItem('attendance')
    this.itemsPerPage = aux && aux.length > 0 ? aux | 0 : 5
    this.handleGetActivities()
//    this.prepareHeaders()
  },
  methods: {
    handleChangeItemsPerPage (v) {
      localStorage.setItem('attendance', v.toString())
      this.itemsPerPage = v
      this.handleGetData(0)
    },
    onSearch (v) {
      this.search = v
      this.handleGetData(0)
    },
    handleChangeDate (v) {
      this.selectedDate = v
      this.handleGetData(0)
    },
    handleGetActivities () {
      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/activities`, 0, 10000, '')
        .then (response => {
          this.activities = response.data.map(item => { 
            return {
              id: item.ID, 
              name: item.Name, 
              tName: utils.getTranslation(JSON.parse(item.Name), this.locale) 
            }
          })
          if (this.activities.length > 0) this.selectedActivity = this.activities[0].id
        })
    },
    handleCheckin (v) {
      this.expanded = this.expanded.indexOf(v) >= 0 ? [] : [v]
    },
    prepareHeaders () {
     // this.headers = await new Promise ((resolve, _) => api.getAllWithoutLimit ('booking', `v1/private/activities/${this.selectedActivity.id}/form-fields`)
      api.getAllWithoutLimit ('booking', `v1/private/activities/${this.selectedActivity}/form-fields`)
        .then(response => {
          let aux = response.shift()
          if (!aux) return
          aux = JSON.parse(aux.FormFields)
          const result = [{ text: 'Tutor', value: 'ident' }]
          result.push(...aux.owner.filter(x => x.key !== 'name').map(item => {
            return {
              text: utils.getTranslation(item.label, this.locale),
              value: item.key,
              type: item.type,
              isValues: true,
            }
          }))
          if (!aux.paxAsCount) result.push(...aux.pax.map(item => {
            return {
              text: utils.getTranslation(item.label, this.locale),
              value: item.key,
              type: item.type,
              isValues: true,
            }
          }))
          result.unshift({ value: 'notes', sortable: false })
          result.push({ value: 'actions', sortable: false })
          this.headers = result
          this.handleGetData(0)
        })
       /*
      api.getAllWithoutLimit ('booking', `v1/private/workspaces/${this.workspaceID}/attendance/form`)
        .then(response => {
          if (!response || response.length === 0) return
          const aux = response.map(item => {
            return {
              text: utils.getTranslation(item.Name, this.locale),
              value: item.ID,
              type: item.Type,
              isValues: true,
            }
          })
          aux.unshift({
            text: utils.getTranslation({ es: 'Nombre tutor/responsable', en: 'Guardian name' }, this.locale),
            value: 'guardianName',
          })
          aux.unshift({
            text: utils.getTranslation({ es: 'Nombre asistente', en: 'Pax name' }, this.locale),
            value: 'ident',
          })
          aux.push({
            value: 'actions'
          })
          //this.headers = aux
          this.handleGetData()
        })
*/
      /*
      this.headers = [
        {
          text: 'Asistente',
          align: 'start',
          sortable: true,
          value: 'paxName',
        },
        { text: 'Edad', value: 'age' },
        { text: 'Habitaci�n', value: 'room' },
        { text: 'Idioma', value: 'locale' },
        { text: 'Tutor legal donde?', value: 'guardianWhere' },
        { text: 'Tel�fono', value: 'phone' },
        { text: '�vino solo?', value: 'arriveOnly' },
        { text: '�se puede ir solo?', value: 'goAlone' },
        { text: 'Observaciones', value: 'note' },
        { value: 'actions', align: 'end', sortable: false }
      ]
  */
    },
    convertValue (type, v) {
      if (type === 'boolean') {
        if (v === true) 
          return utils.getTranslation({ es: 'SI', en: 'YES'}, this.locale)
        else if (v === false) 
          return utils.getTranslation({ es: 'NO', en: 'NO'}, this.locale)
        else return ''
      }
      return v
    },
    prepareData (v) {
      console.log(v.Note)
      const result = {
        ID: v.ID,
        Note: v.Note,
        ident: {
          id: v.ID,
          name: v.name
        },
        guardianName: v.name,
      }
      for (const item of this.headers.filter(x => x.isValues)) {
        result[item.value] = this.convertValue(item.type, v[item.value])
      }
      return result
    },
    handleGetData (startIndex) {
      this.data = []
      this.count = 0
      if (startIndex >=0 ) this.startIndex = startIndex
      let query = `${this.search ? this.search : ''}&date=${this.selectedDate}`
      //if (this.selectedStates.length > 0) query += `&states=${this.selectedStates.join(',')}`
      if (this.selectedActivity) query += `&activities=${this.selectedActivity}`
      query += `&states=${this.selectedStatus.join(',')}`
      //if (this.selectedEvents.length > 0) query += `&events=${this.selectedEvents.join(',')}`

      //if (this.onChangeQuery) this.onChangeQuery(query)

      api.getAll ('booking', `v1/private/workspaces/${this.workspaceID}/reservations`, this.startIndex, this.itemsPerPage, query)
        .then(response => {
          if (!response) return
          this.count = response.count
          if (!response.data || response.data.length === 0) return
          const result = []
          response.data.map(item => {
            const fieldsValues = JSON.parse(item.FieldsValues)
            for (let i = 0; i < fieldsValues.numPax ; i++) {
              let aux = { ID: item.ID.concat('_', i), Note: item.Note }
              aux = Object.assign(aux, fieldsValues.owner, fieldsValues.pax[i])
              result.push(this.prepareData(aux))
            }
/*
            const result = Object.assign({ID: item.ID}, item.FieldsValues.owner, item.FieldsValues.pax)
            item.FieldsValues = JSON.parse(item.FieldsValues)
            for (const pax of item.FieldsValues.pax) {

            }
            return this.prepareData(Object.assign({ID: item.ID}, item.FieldsValues.owner, item.FieldsValues.pax))*/
            return item
          })
          this.data = result
        })
    },
    handleSaveSignature (v) {
      this.img = v
    },
    handleExportPDF () {
      this.loading = true
      api.getAllWithoutLimit ('booking', `v1/private/attendance-reservations/pdf/${this.workspaceID}/${this.selectedDate}/${this.selectedActivity}`, false, `&states=${this.showAll ? 'all' : ''}`)
        .then(response => {
          window.open(response, '_blank')
          this.loading = false
        })

    },
  },
}
</script>

