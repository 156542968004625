var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.headers && _vm.data)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.data,"item-key":"ID","elevation":"0","light":"","locale":_vm.locale,"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [(_vm.editedItem)?_c('v-toolbar',{attrs:{"flat":""}},[_c('span',{staticStyle:{"padding":"0 20px"}},[_vm._v(" Entrada: ")]),_c('signature',{staticStyle:{"margin":"0 20px"},attrs:{"locale":"es","isNew":_vm.editedItem.signatureIni ? false : true,"onSave":_vm.handleCheckin,"disabled":!_vm.editedItem.signatureIni ? false : true}}),_c('span',{staticStyle:{"padding":"0 20px"}},[_vm._v(" Salida: ")]),_c('signature',{staticStyle:{"margin-left":"20px"},attrs:{"locale":"es","isNew":_vm.editedItem.signatureEnd ? false : true,"onSave":_vm.handleCheckout,"disabled":_vm.editedItem.signatureIni && !_vm.editedItem.signatureEnd ? false : true}}),_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.editedItem = null}}},[_vm._v(" "+_vm._s(_vm.$t('common.cancel', _vm.locale))+" ")])],1):_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.handleAdd}},[_vm._v(" "+_vm._s(_vm.$t('common.add', _vm.locale))+" ")])],1)]},proxy:true},{key:"item.timeIni",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTimeFormated(item.timeIni))+" ")]}},{key:"item.timeEnd",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTimeFormated(item.timeEnd))+" ")]}},{key:"item.signatureIni",fn:function(ref){
var item = ref.item;
return [(item.signatureIni)?_c('img',{staticStyle:{"width":"100px","border":"1px solid #cccccc"},attrs:{"src":item.signatureIni}}):_vm._e()]}},{key:"item.signatureEnd",fn:function(ref){
var item = ref.item;
return [(item.signatureEnd)?_c('img',{staticStyle:{"width":"100px","border":"1px solid #cccccc"},attrs:{"src":item.signatureEnd}}):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('delete-item',{attrs:{"id":item.id,"onDeleteItem":_vm.handleDeleteCheckin,"locale":_vm.locale,"onlyIcon":true}}),_c('v-btn',{staticStyle:{"margin-left":"10px"},attrs:{"color":"primary"},on:{"click":function($event){return _vm.handleEdit(item)}}},[_vm._v(" editar ")])]}}],null,false,2830800190)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }