<template>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="showDialog === null"
          :color="isNew ? 'primary' : '#cccccc'"
          :dark="!disabled"
          @click.stop.prevent="handleOpen"
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
        >
          <v-icon>mdi-file-sign</v-icon> {{ $t('booking.attendance.sign', locale) }}
        </v-btn>
      </template>
      <v-card style="background-color: #CCCCCC; height: calc(100vh);">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="handleSave"
            >
              {{ $t('common.save', locale) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="main-container-signature">
            <signature-box 
              v-if="id"
              :id="id"
              ref="box"
            />
        </div>
      </v-card>
    </v-dialog>
</template>
<script>
import utils from '@/services/utils'
import SignatureBox from './SignatureBox'
export default {
  components: {
    SignatureBox,
  },
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    showDialog: {
      type: Boolean,
      default: null,
    },
    locale: {
      type: String,
      required: true,
    },
    onSave: {
      type: Function,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      id: null,
      dialog: false,
    }
  },
  watch: {
    showDialog () {
      this.handleOpen()
    },
  },
  mounted () {
  },
  methods: {
    handleOpen () {
      this.id = utils.getNewID()
      this.dialog = true
    },
    handleSave () {
      const that = this
      //const el = this.$refs.box
      this.$html2canvas(document.getElementById('canvasS'), {
          type: 'dataURL'
        }).then((canvas) => {
          that.onSave(canvas)
          that.dialog = false
      })
    },
  },
}
</script>
<style scoped>
.main-container-signature {
  min-height: calc(100vh - 64px);
}
</style>

