<template>
  <canvas 
    id="canvasS"
    ref="canvasS"
    :key="id"
    :style="{ width: `${w}px`, height: `${h}px` }"
  />
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    w: 500,
    h: 500,
    miCanvas: null,
    lineas: [],
    correccionX: 0,
    correccionY: 0,
    pintarLinea: false,
  // Marca el nuevo punto
    nuevaPosicionX: 0,
    nuevaPosicionY: 0,
    posicion: null,

  }),
  computed: {
    isLandscape () {
      return screen.availHeight < screen.availWidth
    },
  },
  watch: {
    id () {
      this.prepareCanvas()
    },
  },
  mounted () {
    this.prepareCanvas()
  },
  methods: {
    prepareSize () {
      const aux = screen.availWidth > 800 ? 800 : screen.availWidth
      this.w = aux
      this.h = aux * 9 / 16
    },
    prepareCanvas () {
      this.prepareSize ()
      this.lineas = []
      setTimeout(()=>{
        this.miCanvas = this.$refs['canvasS']
        this.miCanvas.width = this.w
        this.miCanvas.height = this.h
        this.miCanvas.addEventListener('mousedown', this.empezarDibujo, false)
        this.miCanvas.addEventListener('mousemove', this.dibujarLinea, false)
        this.miCanvas.addEventListener('mouseup', this.pararDibujar, false)

        // Eventos pantallas táctiles
        this.miCanvas.addEventListener('touchstart', this.empezarDibujo, false)
        this.miCanvas.addEventListener('touchmove', this.dibujarLinea, false)


        this.posicion = this.miCanvas.getBoundingClientRect()
        this.correccionX = this.posicion.x
        this.correccionY = this.posicion.y
      }, 200)
    },
    empezarDibujo () {
      this.pintarLinea = true
      this.lineas.push([])
    },
    
    /**
     * Funcion que guarda la posicion de la nueva línea
     */
    guardarLinea () {
        this.lineas[this.lineas.length - 1].push({
            x: this.nuevaPosicionX,
            y: this.nuevaPosicionY
        })
    },

    /**
     * Funcion dibuja la linea
     */
    dibujarLinea (event) {
        event.preventDefault()
        if (this.pintarLinea) {
            let ctx = this.miCanvas.getContext('2d')
            // Estilos de linea
            ctx.lineJoin = ctx.lineCap = 'round'
            ctx.lineWidth = 5
            // Color de la linea
            ctx.strokeStyle = '#000'
            // Marca el nuevo punto
            if (event.changedTouches == undefined) {
                // Versión ratón
                this.nuevaPosicionX = event.layerX
                this.nuevaPosicionY = event.layerY
            } else {
                // Versión touch, pantalla tactil
                this.nuevaPosicionX = event.changedTouches[0].pageX - this.correccionX
                this.nuevaPosicionY = event.changedTouches[0].pageY - this.correccionY
            }
            // Guarda la linea
            this.guardarLinea()
            // Redibuja todas las lineas guardadas
            ctx.beginPath()
            this.lineas.forEach(function (segmento) {
                ctx.moveTo(segmento[0].x, segmento[0].y)
                segmento.forEach(function (punto) {
                    ctx.lineTo(punto.x, punto.y)
                })
            })
            ctx.stroke()
        }
    },

    /**
     * Funcion que deja de dibujar la linea
     */
    pararDibujar () {
        this.pintarLinea = false
        this.guardarLinea()
    }
  },
}
</script>
<style scoped>
canvas {
  background-color: white;
  /*
  background-color: #0D0909;
  */
} 
</style>

