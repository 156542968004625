<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="dateFormatted"
        label="Fecha"
        persistent-hint
        hide-details
        outlined
        dense
        v-bind="attrs"
        @blur="date = parseDate(dateFormatted)"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      no-title
      @input="menu = false"
    />
  </v-menu>
</template>
<script>
export default {
  props: {
    onChange: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    date: new Date().toISOString().split('T').shift(),
    dateFormatted: new Date().toISOString().split('T').shift(),
    menu: false,
  }),

  computed: {
    computedDateFormatted () {
      return this.formatDate(this.date)
    },
  },

  watch: {
    date () {
      this.dateFormatted = this.formatDate(this.date)
      this.onChange(this.dateFormatted)
    },
  },

  methods: {
    formatDate (date) {
      if (!date) return null
return date
      /*
      const [year, month, day] = date.split('/')
      return `${year}/${month}/${day}`
      */
    },
    parseDate (date) {
      if (!date) return null
      return date
/*
      const [year, month, day] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      */
    },
  },
}
</script>

